@import '../styles/colors';

.external-menu {
    z-index: 2;
    width: 100%;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #D9E1EF;
    -webkit-font-smoothing: antialiased;

    div {
        height: 100%;
    }

    img {
        height: 32px;
        padding: 6px 40px;
    }
}

.external-menu-links {
    display: flex;
    flex-wrap: nowrap;
}

.external-menu-link {
    color: $black;
    display: inline-flex;
    flex-wrap: nowrap;
    align-items: center;
    margin-right: 70px;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 400;
    text-decoration: none;
    letter-spacing: 1px;
    height: 100%;
    transition: all 0.2s ease-in-out;

    &:hover {
        color: $q-teal;
    }

    @media (max-width: 580px) {
        margin-right: 35px;
    }
}
