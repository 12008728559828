$black: #000000;
$q-gray: #0a0b15;
$q-darkpurple: rgb(25, 35, 68);

$q-teal: #85D8D4;
$q-purple: #965BD8;
$q-purple-light: rgb(157, 95, 212);

$text-light: rgb(230, 230, 230);
$text-main: #727E97;

$teal: #00ABA2;
$gray: rgba(114, 126, 151, 1);
$gray-light: rgba(114, 126, 151, 0.6);

