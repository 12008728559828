.disclaimer {
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    bottom: 0;
    left: 0;
    right: 0;
    background: #eeeeee;
    padding: 8px 20px;
    height: 50px;
    z-index: 99999;

    @media (max-width: 580px) {
      height: 70px;
    }
  
    .disclaimer-text {
      font-size: 14px;
      color: #3f3f3f;
      white-space: normal;
      margin-right: 20px;
  
      @media (max-width: 500px) {
        font-size: 90% !important;
      }
    }
  
    .disclaimer-link, .disclaimer-link:hover, .disclaimer-link:visited {
      font-weight: 600;
      font-size: 15px;
      color: #4140DF;
      text-decoration: underline;
  
      &:hover {
        text-decoration: none;
      }
  
      @media (max-width: 500px) {
        font-size: 90% !important;
      }
    }
  
    .button {
      line-height: 55px;
  
      @media (max-width: 500px) {
        line-height: 37px;
        height: 40px;
        font-size: 15px;
        width: 140px;
      }
    }
  }