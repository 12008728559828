@import '../styles/colors';
@import '../styles/fade-in';
@import '../styles/slide-up';
@import '../styles/slide-down';
@import '../../../node_modules/bootstrap/scss/bootstrap-grid';
@import '../../../node_modules/react-modal-video/scss/modal-video.scss';

  // Define icon font
  @font-face {
    font-family: 'IOTA icons';
    font-style: normal;
    font-weight: normal;
    src: url('../fonts/iota-icons.ttf?build=1.0.4') format('truetype');
  }

  @font-face {
    font-family: 'Inter';
    src: url('../fonts/Inter-Regular.ttf');
    font-weight: 400;
  }
  @font-face {
    font-family: 'Inter';
    src: url('../fonts/Inter-SemiBold.ttf');
    font-weight: 600;
  }
  @font-face {
    font-family: 'Inter';
    src: url('../fonts/Inter-Bold.ttf');
    font-weight: 800;
  }
  @font-face {
    font-family: 'Inter';
    src: url('../fonts/Inter-Italic.ttf');
    font-weight: 400;
    font-style: italic;
  }
  @font-face {
    font-family: 'Inter';
    src: url('../fonts/Inter-MediumItalic.ttf');
    font-weight: 600;
    font-style: italic;
  }
  @font-face {
    font-family: 'Inter';
    src: url('../fonts/Inter-BoldItalic.ttf');
    font-weight: 800;
    font-style: italic;
  }


  @font-face {
    font-family: 'Metropolis';
    src: url('../fonts/Metropolis-Regular.otf');
    font-weight: 400;
  }
  @font-face {
    font-family: 'Metropolis';
    src: url('../fonts/Metropolis-SemiBold.otf');
    font-weight: 600;
  }
  @font-face {
    font-family: 'Metropolis';
    src: url('../fonts/Metropolis-Bold.otf');
    font-weight: 800;
  }
  @font-face {
    font-family: 'Metropolis';
    src: url('../fonts/Metropolis-Light.otf');
    font-weight: 200;
    font-style: italic;
  }
  @font-face {
    font-family: 'Metropolis';
    src: url('../fonts/Metropolis-ExtraBold.otf');
    font-weight: 900;
    font-style: italic;
  }

  html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    font-family: 'Inter', 'Nunito Sans', sans-serif;
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
}

body,
html {
    margin: 0;
    height: 100%;
    width: 100%;
    font-family: 'Inter', 'Nunito Sans', sans-serif;
    color: $text-main;
    box-sizing: content-box;
    background-color: #ffffff;
}
button {
    border: none;
    padding: 0;
    outline: none;
    outline-color: initial;
    outline-style: none;
    outline-width: initial;
    cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
h6,
.form-heading {
    font-family: 'Metropolis', 'Nunito Sans', sans-serif;
}

p,
span,
div,
section,
form,
ul,
li {
    font-family: 'Inter', 'Nunito Sans', sans-serif;
}

* {
    outline: none;
}

a {
    text-decoration: none;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

.container {
    margin-left: -15px;
    margin-right: -15px;
}

#__next {
    height: 100%;
    width: 100%;
}

$hero-height: calc(100% - 174px);

#canvas {
    display: none;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    animation: fade-in 1s;
}

.hero {
    height: $hero-height;
    position: relative;
    animation: fade-in 3s;
}

.content-intro {
    position: absolute;
    width: 800px;
    top: 50%;
    left: 50%;
    margin-left: -400px;
    margin-top: -80px;
    font-size: 30px;
    line-height: 1.8;
    letter-spacing: 2px;
}

.content-footer {
    display: flex;
    justify-content: space-between;
    max-width: 580px;
    margin: 30px auto;
    padding: 0 15px;

    @media (max-width: 768px) {
        width: 100%;
        margin: 0 auto;
    }

    @media (max-width: 580px) {
        flex-direction: column;
        align-items: center;
        padding: 0;
    }

    & > .btn {
        &.secondary {
            text-align: center;
            display: inline;
        }
    }
}

.normal-row {
    flex-direction: row !important;
}

.content a {
    color: $text-light;
    text-decoration: none;
}

.content-footer a {
    font-size: 1.4em;
    font-weight: 700;
    z-index: 1000;
    text-decoration: none;
}

.content-footer a div {
    padding: 20px 40px;
}

.content-previous-link {
    & > svg {
        color: #00ABA2;
        font-size: 4.7em;
        cursor: pointer;

        &:hover, &:focus, &:active {
            opacity: 0.6;
        }
    }
}

.video-container {
    margin: 20px 0;
    text-align: center;
}

.video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-height: 450px;
}

.hidden {
    display: none !important;
}

@media only screen and (min-width: 320px) and (max-width: 768px) {
    #canvas {
        overflow: hidden;
    }

    .hero {
        margin-top: 180px;
        height: calc(100% - 90px);
        max-height: 400px;
    }

    .content-footer a {
        font-size: 16px;
        margin: auto;
    }

    .content-intro {
        width: 90%;
        left: 5%;
        top: 100px;
        margin-left: 0;
        position: relative;
        text-align: center;
        font-size: 18px;
    }
}

* {
    outline: none;
    -webkit-font-smoothing: antialiased;
}

.tablet-hidden {
    display: inline;
    @media screen and (max-width: 1120px) {
        display: none;
    }
}

.mobile-hidden {
    display: inline;
    @media screen and (max-width: 585px) {
        display: none;
    }
}

.desktop-hidden {
    display: inline;
    @media screen and (max-width: 970px) {
        display: none;
    }
}

.mobile-only {
    display: inline;
    @media screen and (min-width: 586px) {
        display: none;
    }
}

.ReactTable {
    .rt-thead.-header {
        box-shadow: none !important;
        font-weight: bold;
        border-bottom: 1px solid rgba(115,143,212,0.15);
    }

    .rt-thead .rt-th {
        text-align: left;
        color: #009fff;
        background: rgba(240,240,240,1);
    }

    .rt-thead .rt-tr {
        font-weight: bold;
    }

    .-pagination {
        box-shadow: none !important;
    }
}

@import '../../../node_modules/slick-carousel/slick/slick.css';
@import '../../../node_modules/slick-carousel/slick/slick-theme.css';

.slick-dots li button:before {
    color: #EEF2FA;
    font-size: 8px;
    opacity: 0.7;
}

.slick-dots li.slick-active button:before {
    opacity: 1;
    color: #FFFFFF;
}

.slick-prev,
.slick-next {
    width: 35px;
    height: 69px;
    opacity: .75;

    &:hover {
        opacity: 1;
    }
}
