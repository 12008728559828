@import '../styles/colors';

.content-main {
    margin: 0 auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.intro-page {
    @media (max-width: 1200px) {
        position: relative;
        top: -200px;
    }

    @media (max-width: 840px) {
        top: -150px;
    }

    @media (max-width: 680px) {
        top: -100px;
    }
}

.intro-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 970px;
    width: 100%;
    padding-top: 150px;
    padding-bottom: 100px;

    .intro {
        display: flex;
        flex-direction: column;
        max-width: 360px;
        width: 100%;

        .text {
            text-align: left;
            line-height: 28px;

            @media (max-width: 1200px) {
                text-align: center;
            }
        }

        .title {
            font-size: 48px;
            line-height: 54px;
            letter-spacing: 0.03em;
            color: #131F37;
            margin: 20px 0;
            text-transform: none;

            @media (max-width: 580px) {
                font-size: 36px;
                line-height: 36px;
            }
        }

        .subtitle {
            font-size: 20px;
            line-height: 30px;
            color: #4140DF;

            @media (max-width: 680px) {
                font-size: 16px;
            }
        }
    }

    .intro-buttons-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: flex-start;
        height: 125px;
        width: 100%;
        margin: 50px 0 70px;

        .links-wrapper {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 240px;
            margin-top: 15px;

            a {
                font-size: 16px;
                line-height: 20px;
                color: #4140DF;
                text-decoration: underline;

                &:visited {
                    color: #4140DF;
                }
            } 
        }

        @media (max-width: 1200px) {
            align-items: center;
        }

        @media (max-width: 840px) {
            margin: 50px 0 0;
        }
    }

    @media (max-width: 1200px) {
        top: -50px;
        padding-top: 0;
        padding-bottom: 0;
        align-items: center;
    }
}

.features-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 180px 70px;
    background: #FFFFFF;

    @media (max-width: 1400px) {
        padding: 0 30px 70px;
    }

    @media (max-width: 1000px) {
        padding: 0 50px 70px;
    }

    @media (max-width: 540px) {
        padding: 0 0 70px;
    }

    .key-topic-wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
        margin-top: 50px;
        max-width: 1150px;
        flex-wrap: wrap;

        .key-topic {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            max-width: 245px;
            padding: 30px 50px;

            & > * {
                margin: 10px 0;
            }

            img {
                width: 80px;
            }
        }
    }
}

.features-heading-wrapper {
    max-width: 550px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-top: 100px;
    width: 100%;

    .text {
        margin-top: 25px;

        @media (max-width: 680px) {
            margin: 25px 50px 0;
        }
    }

    @media (max-width: 960px) {
        margin-top: 50px;
    }
}

.intro-page-btn {
    &.secondary {
        cursor: pointer;
        transition: all 0.2s ease-in-out;

        &:hover {
            opacity: 0.9;
        }
    }
}

.asset {
    position: absolute;

    &.heading {
        width: 970px;
        top: -30px;
        right: -100px;
        z-index: 0;

        @media (max-width: 1200px) {
            position: relative;
            top: 30px;
            right: -30px;
        }

        @media (max-width: 1000px) {
            width: 90%;
        }

        @media (max-width: 768px) {
            top: 15px;
        }

        @media (max-width: 680px) {
            top: 0;
        }
    }

    &.video {
        width: 100%;
        top: -30px;
        right: -100px;
        z-index: 0;
    }
}

.logotypes-wrapper {
    height: 92px;
    width: 100%;
    background: #F8FAFF;
    display: flex;
    justify-content: center;

    img {
        margin: 0 30px;
        width: 110px;

        @media (max-width: 1000px) {
            margin: 10px 30px;
        }
    }

    @media (max-width: 1000px) {
        flex-wrap: wrap;
        justify-content: space-evenly;
        padding: 20px 0;
        height: auto;
    }
}

.marketplace-wrapper {
    background: #EEF2FA;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 50px 0;

    img {
        width: 100%;
        max-width: 600px;

        @media (max-width: 1200px) {
            width: 45%;
        }

        @media (max-width: 960px) {
            width: 70%;
        }
    }

    .marketplace-text-wrapper {
        max-width: 440px;
        padding-left: 50px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .text {
            text-align: left;
            margin-top: 25px;

            @media (max-width: 960px) {
                text-align: center;
            }
        }

        @media (max-width: 1200px) {
            width: 45%;
            padding-left: 20px;
        }

        @media (max-width: 960px) {
            width: 70%;
            padding-left: 0;
        }
    }

    @media (max-width: 960px) {
        flex-direction: column;
        align-items: center;
        padding: 20px 20px 70px;
    }
}

.video-section-wrapper {
    min-height: 600px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url('/static/backgrounds/video_section_desktop_background.svg');

    .desktop-player {
        position: relative;

        .desktop {
            width: 560px;
            border-radius: 6px;
            cursor: pointer;
        }

        .play {
            cursor: pointer;
            position: absolute;
            left: calc(50% - 46px);
            top: calc(50% - 46px);
            width: 92px;
            height: 92px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
            background-image: url('/static/backgrounds/play.svg');

            &:hover {
                background-image: url('/static/backgrounds/play_hover.svg');
            }
        }
    }

    .face {
        display: block;
        height: 350px;
        width: 350px;
    }
 
    .mobile {
        width: 80px;
        height: 80px;
        display: none;
        cursor: pointer;
    }

    .graphic-wrapper {
        position: relative;

        .quote-graphic {
            position: absolute;
            top: 5%;
            right: 0%;

            @media (max-width: 1100px) {
                width: 90px;
            }

            @media (max-width: 768px) {
                width: 80px;
            }

            @media (max-width: 680px) {
                width: 50px;
            }
        }
    }

    .video-section-text-wrapper {
        display: flex;
        flex-direction: column;
        max-width: 290px;
        width: 100%;

        a {
            color: #F6F8FC;
            text-decoration: underline;
        }

        .text {
            text-align: left;
            margin: 15px 0;
            color: #F6F8FC;
        }

        &.how-it-works {
            padding-right: 100px;

            a {
                margin-top: 20px;
            }
        }

        &.what-they-say {
            padding-left: 70px;
            max-width: 600px;

            .text {
                margin: 10px 0;
            }

            .quote {
                color: #FFFFFF;
                font-style: italic;
                font-weight: 600;
                font-size: 18px;
                line-height: 24px;
            }

            .name {
                color: #F6F8FC;
            }

            a {
                background: url('/static/arrow-small.svg') no-repeat;
                background-position: 230px 6px;
            }

            .link {
                letter-spacing: 0.15em;
                text-transform: uppercase;
                font-weight: 800;
                font-size: 14px;
                line-height: 30px;
                cursor: pointer;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}

.slider-content,
.video-section-wrapper {
    position: relative;
    width: 100%;
    display: flex !important;
    justify-content: center;
    align-items: center;

    @media (max-width: 1100px) {
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        padding: 50px 30px 30px;
        height: 800px;

        &.reverse {
            flex-direction: column-reverse;
        }

        .face {
            display: block;
            height: 300px;
            width: 300px;
        }

        .video-section-text-wrapper.what-they-say,
        .video-section-text-wrapper.how-it-works {
            padding-left: 0;
            padding-right: 0;

            .text {
                text-align: center;
            }

            a {
                text-align: center;
                background-position: 325px 6px;
            }
        }
    }

    @media (max-width: 680px) {
        background-image: url('/static/backgrounds/video_section_mobile_background.svg');
        min-height: unset;

        .desktop-player {
            .desktop {
                width: 80vw;
            }

            .play {
                width: 70px;
                height: 70px;
                left: calc(50% - 35px);
                top: calc(50% - 35px);
            }
        }

        .video-section-text-wrapper.what-they-say .quote  {
            font-size: 16px;
            line-height: 22px;
        }
    }

    @media (max-width: 500px) {
        &.reverse {
            height: 500px;
        }
        
        .mobile {
            display: block;
        }
        .desktop, .play {
            display: none;
        }
    }
}

.what-they-say {
    flex-direction: column;

    > .title {
        color: #F6F8FC;
        margin: 60px 0 30px;
    }
}

.slider-content {
    min-height: 460px;
    background-image: none;
    width: 80vw !important;
    margin: 0 auto;

    @media (max-width: 110px) {
        .face {
            display: block;
            height: 396px;
            width: 396px;
        }
    }

    @media (max-width: 840px) {
        .desktop-player {
            .desktop {
                width: 500px;
            }
        }
    }

    @media (max-width: 680px) {
        padding: 10px 30px 30px;
        .face {
            margin-bottom: 20px;
            display: block;
            height: 183px;
            width: 183px;
        }

        .desktop-player {
            .desktop {
                width: 50vw;
            }
        }
    }
}

.slider-container {
    width: 88%;
    display: flex;
    flex-direction: column;
    align-self: center;
    margin-bottom: 80px;

    @media (max-width: 1100px) {
        margin-bottom: 50px;
    }

    @media (max-width: 680px) {
        width: 82%;
        margin: 0;
    }
}

.request-map-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 0;

    .text-wrapper {
        width: 80%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        height: 100px;
        justify-content: space-around;
        margin-bottom: 50px;
    }

    .map-cta {
        margin-top: 55px;
    }

    @media (max-width: 840px) {
        padding: 50px 0;

        .text-wrapper {
            width: 100%;
            height: 70px;
            align-items: center;
        }
    }
}

.key-components-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #5067EB;
    padding: 100px 0;

    .text {
        color: rgba(255, 255, 255, 0.7);
        text-align: left;
        margin: 5px 0;

        &.title,
        &.subtitle {
            color: #FFFFFF;
        }

        @media (max-width: 1200px) {
            text-align: center;
        }

        @media (max-width: 680px) {
            padding: 0 30px;
        }
    }

    .key-components-description {
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: 435px;
        width: 100%;

        .text {
            margin: 15px 0;
        }

        @media (max-width: 1200px) {
            margin-bottom: 30px;
        }
    }

    .key-components-list {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .key-component {
            display: flex;
            flex-direction: row;
            margin: 20px 0 20px 70px;

            .key-component-description {
                display: flex;
                flex-direction: column;
                max-width: 435px;
                width: 100%;
                padding-left: 50px;

                .text {
                    text-align: left;
                }
            }

            @media (max-width: 768px) {
                flex-direction: column;
                margin: 40px 0;

                .key-component-description {
                    padding-left: 0;

                    .subtitle {
                        margin: 25px 0 20px;
                    }

                    .text {
                        text-align: center;
                    }
                }
            }
        }
    }

    @media (max-width: 1200px) {
        flex-direction: column;
    }

    @media (max-width: 840px) {
        padding: 50px 30px;
    }
}

.callToAction-wrapper {
    background: #EEF2FA;
    width: 100%;
    min-height: 540px;
    padding-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .text {
        max-width: 425px;
        width: 100%;
        margin: 15px 0;

        @media (max-width: 680px) {
            padding: 0 30px;
        }
    }

    .callToAction-buttons-wrapper {
        margin-top: 40px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        max-width: 550px;
        width: 100%;
    }

    @media (max-width: 840px) {
        padding-top: 50px;
        min-height: 490px;
    }

    @media (max-width: 768px) {
        min-height: 440px;
    }

    @media (max-width: 680px) {
        min-height: 490px;
        .callToAction-buttons-wrapper {
            flex-direction: column;
            align-items: center;
            height: 120px;
        }
    }

    @media (max-width: 580px) {
        min-height: 440px;
    }
}
