@import '../styles/colors';

.text {
    font-size: 16px;
    line-height: 24px;
    color: #485776;
    letter-spacing: 0.05em;
    text-align: center;

    &.title {
        font-family: 'Metropolis', 'Nunito Sans', sans-serif;
        font-size: 36px;
        font-weight: 900;
        line-height: 36px;
        letter-spacing: 0.03em;
        color: #131F37;

        @media (max-width: 840px) {
            font-size: 26px;
            line-height: 28px;
        }

        @media (max-width: 680px) {
            font-size: 22px;
            line-height: 26px;
        }
    }

    &.subtitle {
        font-family: 'Metropolis', 'Nunito Sans', sans-serif;
        font-size: 22px;
        line-height: 28px;
        font-weight: 600;
        letter-spacing: 0.03em;
        color: #485776;

        @media (max-width: 680px) {
            font-size: 18px;
            line-height: 24px;
        }
    }

    &.bold {
        font-weight: 800;
    }

    &.light {
        color: #B9C4D1;
    }

    &.mini {
        font-size: 14px;
        line-height: 19px;
        color: rgba(114, 126, 151, 0.8);
    }

    &.micro {
        font-size: 11px;
        line-height: 26px;
        color: rgba(89, 96, 113, 0.7);
    }

    &.extra-large {
        font-family: 'Metropolis', 'Nunito Sans', sans-serif;
        font-size: 60px;
        line-height: 71px;
        letter-spacing: 0.15em;
        color: #50545F;

        @media (max-width: 768px) {
            font-size: 50px;
            line-height: 61px;
            letter-spacing: 0.1em;
        }

        @media (max-width: 580px) {
            font-size: 40px;
            line-height: 51px;
            letter-spacing: 0.05em;
        }
    }

    &.with-dot {
        &::before {
            display: inline-block;
            content: '';
            border-radius: 5px;
            height: 10px;
            width: 10px;
            margin-right: 1rem;
            background-color: #C583FF;
          }
    }

    &.content-title {
        font-family: 'Metropolis', 'Nunito Sans', sans-serif;
        font-size: 56px;
        font-weight: 800;
        line-height: 67px;
        letter-spacing: 0.15em;
        color: #50545F;
    }

    &.content-subtitle {
        font-family: 'Metropolis', 'Nunito Sans', sans-serif;
        font-size: 28px;
        font-weight: 800;
        line-height: 32px;
        letter-spacing: 0.15em;
        text-transform: uppercase;
        text-align: left;
        color: #596071;
    }
}
