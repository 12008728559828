@import '~react-md/src/scss/react-md';

@include react-md-everything;

.demo-page {
  padding-top: 100px;
}

.demo-header {
  display: flex;
  flex-direction: column;
  align-self: center;
  max-width: 970px;
  width: 100%;
  margin: 0 auto;

  .text {
    font-size: 14px;
    line-height: 21px;
    text-align: left;
    max-width: 440px;
  }

  .title {
    font-size: 64px;
    line-height: 64px;
    margin-bottom: 15px;
  }

  .links {
    margin: 15px 0 20px;
    font-size: 22px;
    line-height: 26px;
    font-weight: 600;
    white-space: pre-wrap;

    a {
      color: #485776;
      &:hover {
        text-decoration: underline;
      }

      &:visited {
        color: #485776;
      }
    }
  }

  @media (max-width: 1000px) {
    margin: 0 30px;
  }

  @media (max-width: 500px) {
    width: auto;

    .title {
      font-size: 44px;
      line-height: 44px;
    }
  }
}

.request-data-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 50px;

  .demo-page-navigation {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: relative;
    max-width: 970px;

    @media (max-width: 1000px) {
      width: calc(100% - 60px);
    }
  }

  .buttons-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    position: absolute;
    top: 12px;
    right: 0;
    z-index: 2;

    @media (max-width: 400px) {
      top: 205px;
    }
  }

  .md-tabs-container {
    width: 100%;
    z-index: 1;

    .md-tabs {
      .md-tab {
        align-items: flex-start;
        margin-right: 30px;
        max-width: 100px;
        min-width: 100px;
        background-repeat: no-repeat;
        background-position: 0 32px;

        &:nth-child(2) {
          max-width: 177px;
        }

        &:nth-child(4) {
          max-width: 185px;
        }

        .md-tab-label {
          padding-left: 5px;
          font-weight: bold;
          font-size: 14px;
          line-height: 17px;
          letter-spacing: 0.05em;
          text-transform: uppercase;
          color: #131F37;
        }

        &.cfp {
          background-image: url('/static/demo/cfp.svg');
        }

        &.proposal {
          background-image: url('/static/demo/proposal.svg');
        }

        &.acceptProposal {
          background-image: url('/static/demo/acceptProposal.svg');
        }

        &.md-tab--active {
          &.cfp {
            background-image: url('/static/demo/cfp_selected.svg');
          }

          &.proposal {
            background-image: url('/static/demo/proposal_selected.svg');
          }

          &.acceptProposal {
            background-image: url('/static/demo/acceptProposal_selected.svg');
          }
        }
      }

      @media (max-width: 1025px) {
        .md-tab {
          &:nth-child(2) {
            max-width: 187px;
          }

          &:nth-child(4) {
            max-width: 195px;
          }
          .md-tab-label {
            padding-left: 15px;
          }
        }
      }

      @media (max-width: 768px) {
        flex-direction: column;

        .md-tab--active {
          border-bottom: 2px solid #5067EB;
        }
      }
    }

    .md-tab-indicator {
      background: #5067EB;
      max-width: 205px;

      @media (max-width: 768px) {
        display: none;
      }
    }
  }
  .assets-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    margin-bottom: 25px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url('/static/backgrounds/demo_section_desktop_background.svg');

    @media (max-width: 950px) {
      background-image: url('/static/backgrounds/demo_section_mobile_background.svg');
    }

    .assets {
      position: relative;

      @media (max-width: 950px) {
        min-height: 750px
      }
      @media (max-width: 520px) {
        min-height: 950px;
      }
    }

    .no-assets {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      padding: 20px;
      height: 280px;
      width: 280px;

      .title {
        font-size: 22px;
        line-height: 21px;
        color: #000000;
      }
    }
  }
}

.map {
    .header {
        padding: 0 12px;
        display: flex;
        flex-direction: column;
        width: 300px;
        position: absolute;
        z-index: 1;
        padding: 50px 0 0 130px;

        .info {
            font-size: 14px;
            line-height: 21px;
            width: 200px;
            text-align: left;
            margin-top: 15px;
        }

        .title {
            color: #485776;
            font-weight: 800;
            text-align: left;
        }

        @media (max-width: 760px) {
          padding-top: 50px;
          padding-left: 30px;
        }
        @media (max-width: 520px) {
          width: 200px;
        }
        @media (max-width: 400px) {
          display: none;
        }
    }
}

.popup-card {
  cursor: default;
  transition: box-shadow 0.2s ease-out;
  width: 300px;
  height: 385px;
  border: 1px solid #F2F5FB;;
  background-color: #FFFFFF;
  box-shadow: 0px 2px 10px rgba(65, 64, 223, 0.2);
  border-radius: 6px;

  .popup-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 26px 0 24px 29px;
    height: 48px;
    background: #5067EB;
    border-radius: 6px 6px 0px 0px;

    .type {
      font-size: 14px;
      font-weight: 600;
      font-size: 14px;
      line-height: 26px;
      text-transform: uppercase;
      color: #ADBFD2;
    }

    .operation {
      font-size: 20px;
      color: #fff;
      line-height: 26px;
      text-transform: capitalize;
    }
  }

  .popup-body {
    padding: 26px 0 24px 29px;
    border-top: none;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .popup-data {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 40px;

      &:not(:last-of-type) {
        margin-bottom: 25px;
      }

      .key {
        color: #485776;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
      }

      .value {
        font-size: 16px;
        line-height: 16px;
        color: #485776;
      }
    }
  }

  @media (max-width: 520px) {
    width: 220px;
  }

  @media (max-width: 400px) {
    display: none;
  }
}
