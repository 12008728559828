@import '../styles/colors.scss';
@import '../styles/fade-in.scss';
@import '../styles/slide-up.scss';

.page {
    height: 100%;
    position: relative;
    overflow-x: hidden;

    .show-nav {
        width: 30px;
        height: 30px;
        position: fixed;
        top: 24px;
        right: 20px;
        z-index: 3000;
        display: none;
        background: url('/static/menu.svg') center no-repeat;
        opacity: 0.8;
    }

    footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        border-top: 8px solid #4140DF;
        background-color: #131F37;
        padding: 40px 100px;

        .footer-links {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            width: 100%;
            max-width: 680px;
            margin: 40px auto;

            @media (max-width: 540px) {
                flex-direction: column;
                height: 150px;
            }

            > a {
                color: #F6F8FC;
                font-size: 14px;
                letter-spacing: 0.05em;

                &:hover {
                    text-decoration: underline;
                }

                &:visited {
                    color: #F6F8FC;
                }
            }
        }

        p {
            font-size: 12px;
            line-height: 18px;
            letter-spacing: 0.05em;
            text-align: center;
            -webkit-font-smoothing: antialiased;
            color: #8493AD;

            &.copy {
                color: #F6F8FC;
                margin-top: 30px;
            }
        }

        @media (max-width: 680px) {
            padding: 40px;
        }
    }

    .mobile-logo {
        display: none;
        z-index: 1000;
        position: absolute;
        top: 22px;
        left: 40px;
        height: 40px;
        width: 40px;
        cursor: pointer;
    }

    .footer-iota-logo {
        width: 222px;
    }
}
