@import '../styles/colors';

.contact-form {
    background-color: #fff;
    padding: 50px;
    text-align: center;
    z-index: 3;
    background: #FFFFFF;
    box-shadow: 0px 3px 20px rgba(80, 103, 235, 0.25);
    border-radius: 6px;

    .form-heading {
        font-size: 36px;
        line-height: 36px;
        text-align: center;
        color: #131F37;
        margin: 15px 40px 30px;
    }

    .bottom {
        position: absolute;
        height: 50px;
    }

    .form {
        width: 100%;
        z-index: 3;
    }

    .checkbox-wrapper {
        display: flex;
        flex-direction: column;
        margin-top: 13px;
    }

    .checkbox {
        outline: none;
        height: 15px;
        width: 20px;
        transform: scale(1.2);
        cursor: pointer;
        margin: 15px 15px 0 2px;

        &:before {
          -webkit-transition: all .3s ease-in-out;
          -moz-transition: all .3s ease-in-out;
          transition: all .3s ease-in-out;
          content: "";
          position: absolute;
          left: 0;
          top: -2px;
          z-index: 1;
          width: 15px;
          height: 15px;
          border: 2px solid #777;
        }

        &:checked:before {
          -webkit-transform: rotate(-45deg);
          -moz-transform: rotate(-45deg);
          -ms-transform: rotate(-45deg);
          -o-transform: rotate(-45deg);
          transform: rotate(-45deg);
          width: 22px;
          height: 10px;
          border-color: #4140DF;
          border-top-style: none;
          border-right-style: none;
        }

        &:after {
          content: "";
          position: absolute;
          left: 0;
          width: 18px;
          height: 18px;
          background: #fff;
        }
    }

    .label {
        cursor: pointer;
        font-family: 'Inter', 'Nunito Sans', sans-serif;
        font-size: 14px;
        font-weight: normal;
        font-style: italic;
        font-stretch: normal;
        line-height: 17px;
        letter-spacing: normal;
        text-align: left;
        color: #727E97;
        margin-bottom: 5px;

        a {
            color: #4140DF;
            text-decoration: none;

            &:visited {
                color: #4140DF;
            }
        }
    }

    .input {
        font-family: 'Inter', 'Nunito Sans', sans-serif;
        background: #EEF2FA;
        color: #727E97;
        border: none;
        padding: 15px 25px;
        margin: 20px 0 0;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.05em;
        font-weight: normal;
        outline: none;
    }

    .textarea {
        font-family: 'Inter', 'Nunito Sans', sans-serif;
        color: #727E97;
        height: 155px;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.05em;
        padding: 15px 25px;
        margin: 20px 0 0;
        border: none;
        background: #EEF2FA;
        border: none;
        outline: none;
        resize: none;
    }

    .control-wrapper {
        width: 100%;
        margin-top: 40px;
        padding: 0;
    }

    .input-form-wrapper {
        display: flex;
        flex-direction: column;
    }

    .column-form-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;

        @media (max-width: 768px) {
          margin: 0;
          padding: 0;
        }
    }

    .error-wrapper, success-wrapper {
        width: 100%;
        max-width: 1440px;
        padding: 0 15px;
        margin-right: auto;
        margin-left: auto;
        margin-top: 60px;
    }

    .success-wrapper {
        margin: 60px auto 75px;
        padding: 0;
    }

    .error, .success {
        padding: 15px 0 0px;
        font-size: 13px;
        font-weight: 800;
        letter-spacing: 0.84px;
        text-align: center;
        text-transform: uppercase;
        color: #4140DF;

        @media (max-width: 760px) {
          margin-bottom: 40px;
        }
    }
}

.contact-form-wrapper {
    position: relative;
    top: -150px;
    max-width: 720px;
    width: 100%;

    @media (max-width: 960px) {
        max-width: 600px;
    }

    @media (max-width: 768px) {
        top: -100px;
        max-width: 480px;

        .contact-form {
            padding: 25px 40px;

            .form-heading {
                margin: 20px 0;
                font-size: 36px;
            }
        }
    }

    @media (max-width: 580px) {
        max-width: 390px;
        width: 100%;
        padding: 30px;
    }
}
