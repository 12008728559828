@import '../styles/colors';

.nav {
    z-index: 1;
    position: relative;
    left: 0;
    right: 0;
}

.menu {
    z-index: 2;
    width: 100%;
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #ffffff;
    -webkit-font-smoothing: antialiased;

    & > div {
        height: 100%;
        display: flex;
        align-items: center;
    }

    @media (max-width: 768px) {
        height: 75px;
    }

    @media (max-width: 580px) {
        height: 60px;
    }
}

.menu-logo {
    z-index: 5000;

    & > a > img {
        width: 215px;
        height: 42px;
        cursor: pointer;
        padding: 6px 40px;

        @media (max-width: 768px) {
            width: 160px;
            padding: 6px 20px;
        }

        @media (max-width: 580px) {
            width: 100px;
            padding: 6px 10px;
        }
    }
}

.menu-links {
    flex-wrap: nowrap;
    margin: 0;
}

.menu-link,
.menu-link-mobile {
    color: #485776;
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    display: inline-flex;
    flex-wrap: nowrap;
    align-items: center;
    padding: 0px 25px;
    text-transform: uppercase;
    text-decoration: none;
    height: 100%;
    transition: all 0.2s ease-in-out;

    &:hover,
    &:focus,
    &:active {
        color: $black;
    }

    &:last-child {
        background: #F2F5FB;
    }

    &.contents {
        svg {
            margin-right: 20px;
            font-size: 25px;
        }

        & > span {
            @media (max-width: 768px) {
                display: none;
            }
        }

        @media (max-width: 768px) {
            margin-left: 0;
            padding-left: 20px;
        }
    }

    &.mini {
        margin: 0 40px;

        @media (max-width: 768px) {
            margin: 0 30px;
        }

        @media (max-width: 585px) {
            margin: 0 10px;
        }
    }

    @media (max-width: 768px) {
        padding: 0;
    }
}

.menu-link-mobile.intro {
    display: none;
}

@media (max-width: 580px) {
    .menu-link.intro {
        display: none;
    }

    .menu-link-mobile.intro {
        display: inline-flex;
    }
}
