@import '../styles/colors';

.chapters-outer-wrapper {
    background-color: #ffffff;
    overflow: hidden;

    @media (max-width: 768px) {
        overflow-y: visible;
        overflow-x: visible;
    }
}

.chapters-overlay {
    max-width: 750px;
    width: 100%;
    float: right;
    padding: 200px 150px 200px 100px;
    min-height: 100vh;
    height: 100%;
    background-color: #ffffff;

    @media (max-width: 920px) {
        padding: 100px 0;
        max-width: 750px;
    }

    @media (max-width: 768px) {
        padding: 50px 0;
        max-width: 550px;
    }

    @media (max-width: 600px) {
        padding: 50px 0;
        max-width: 500px;
    }

    .label {
        font-size: 14px;
        line-height: 26px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #5067EB;
    }

    .chapters-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        .title {
            font-size: 48px;
            font-weight: 800;
            line-height: 45px;
            color: inherit;
            margin-right: 50px;
            text-align: right;
            display: none;
        }

        .subtitle {
            font-size: 20px;
            font-weight: 900;
            line-height: 25px;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            max-width: 525px;
            text-align: left;
        }

        .read {
            font-size: 20px;
            font-weight: 800;
            line-height: 25px;
            color: inherit;
            text-transform: uppercase;
            color: #4140DF;
            position: absolute;
            right: -400px;
            transition: 0.55s;

            @media (max-width: 768px) {
                display: none;
            }
        }

        .chapter {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: end;
            padding: 30px 0;
            width: 100%;
            color: #485776;
            cursor: pointer;
            position: relative;
            overflow: hidden;
            opacity: 0.5;
            transition: opacity 0.55s ease-in-out;

            &:hover {
                opacity: 1;
                transition: opacity 0.55s ease-in-out;

                & > .read {
                    transition: 0.55s;
                    right: 50px;
                }
            }

            & > .title {
                width: 75px;
            }

            & > .subtitle {
                position: relative;
                text-align: left;
                word-wrap: break-word;
                max-width: 525px;

                &:after {
                    content: '';
                    background: #4140DF;
                    position: absolute;
                    left: 0;
                    height: 2px;
                    width: 50px;
                    bottom: -20px;
                }
            }

            &.intro {
                display: none;

                @media (max-width: 768px) {
                    display: flex;
                }
            }

            @media (max-width: 768px) {
                align-items: normal;
                padding: 25px 0;

                .title {
                    font-size: 40px;
                    margin-right: 30px;
                }

                .subtitle {
                    font-size: 22px;
                    line-height: 20px;
                }
            }

            @media (max-width: 580px) {
                padding: 20px 0;

                .title {
                    font-size: 30px;
                }

                .subtitle {
                    font-size: 16px;
                    width: 100%;
                }
            }
        }

        .subtopics {
            max-height: 0;
            transition: max-height 0.55s ease-out;
            overflow: hidden;

            @media (max-width: 768px) {
                overflow: visible;
                margin: 15px 0 25px;
                max-height: 600px;
            }

            &.enabled {
                margin: 15px 0 25px;
                max-height: 600px;
                transition: max-height 0.55s ease-in;
            }

            .subtopic {
                margin-top: 20px;
                margin-left: 55px;
                color: #50545f;
                cursor: pointer;
                position: relative;
                width: 700px;
                opacity: 0.5;
                display: flex;
                transition: opacity 0.55s ease-in-out;

                & > .read {
                    font-size: 15px;
                    top: 0;
                }

                &:hover {
                    opacity: 1;
                    transition: opacity 0.55s ease-in-out;

                    & > .read {
                        transition: 0.55s;
                        right: 70px;
                    }
                }

                .title {
                    font-size: 25px;
                    margin-right: 50px;
                    width: 75px;
                }

                .subtitle {
                    font-size: 15px;
                }

                @media (max-width: 768px) {
                    display: flex;
                    width: 100%;

                    .title {
                        font-size: 20px;
                        padding-left: 10px;
                        margin-right: 30px;
                        width: 80px;
                    }

                    .subtitle {
                        text-align: left;
                        word-wrap: break-word;
                        width: 100%;
                        max-width: 440px;
                    }
                }

                @media (max-width: 600px) {
                    .title {
                        width: 60px;
                    }

                    .subtitle {
                        font-size: 13px;
                        max-width: 300px;
                    }
                }
            }

            .subtitle {
                font-size: 16px;
            }
        }

        @media (max-width: 600px) {
            margin-left: 20px;
            margin-right: 40px;
        }
    }

    .chapters-wrapper,
    .submodules-wrapper {
        a {
            width: 100%;
        }
    }

    .close-nav {
        width: 30px;
        height: 30px;
        position: fixed;
        top: 100px;
        right: 120px;
        z-index: 10000;
        background: url('/static/quit.svg') center no-repeat;
        cursor: pointer;
        opacity: 0.5;

        &:hover {
            opacity: 1;
        }

        @media (max-width: 768px) {
            top: 30px;
            right: 30px;
        }
    }

    .submodules-wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .chapter {
            max-width: 800px;
            width: 100%;
        }
    }
}
