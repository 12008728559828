@import '../styles/colors';
@import '../styles/fade-in';
@import '../styles/slide-right';
@import '../styles/slide-up';

@font-face {
	font-family: 'icomoon';
	src:url('../fonts/icomoon/icomoon.eot');
	src:url('../fonts/icomoon/icomoon.eot?#iefix') format('embedded-opentype'),
		url('../fonts/icomoon/icomoon.woff') format('woff'),
		url('../fonts/icomoon/icomoon.ttf') format('truetype'),
		url('../fonts/icomoon/icomoon.svg#icomoon') format('svg');
	font-weight: normal;
	font-style: normal;
}

/* Pseudo elements for icons */
.navigation-btn:before,
.icon-arrow-right:before,
.icon-arrow-left:before {
	font-family: 'icomoon';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	position: relative;
	-webkit-font-smoothing: antialiased;
}

.icon-arrow-right:before {
	content: "\e00d";
}

.icon-arrow-left:before {
	content: "\e003";
}

.navigation-btn {
    transition: all 0.3s;

    border-radius: 6px;
    border: 2px solid #4140DF;
    color: #4140DF;
    background-color: #FFFFFF;
    overflow: hidden;

    font-weight: 800;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    cursor: pointer;
    padding: 15px 80px;
	width: 100px;
    display: inline-block;
    margin: 15px 0px;
    outline: none;
    position: relative;
    outline: none;
	user-select: none;

    align-items: flex-start;
    text-indent: 0px;
    text-shadow: none;
    word-spacing: normal;
    -webkit-appearance: button;
    -webkit-writing-mode: horizontal-tb !important;
    text-rendering: auto;

    &:before {
        position: absolute;
        height: 100%;
        font-size: 125%;
        line-height: 3.5;
        color: #FFFFFF;
        transition: all 0.3s;
    }

    &.next {
        background: #4140DF;
        color: #FFFFFF;

        &:before {
            left: 65%;
            opacity: 0;
            bottom: 7px;
        }

        &:hover {
						opacity: 0.95;

            &:before {
                left: 85%;
                opacity: 1;
								color: #FFFFFF;
            }
        }
    }

    &.back {
        &:before {
            left: 30%;
            opacity: 0;
            bottom: 7px;
        }

        &:hover {
						background: #f2f5fb;
            &:before {
                left: 10%;
                opacity: 1;
								color: #4140DF;
            }
        }
    }
}

.content-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 100px 20px 20px;
    max-width: 580px;
    margin: auto;

    .subtitle {
        margin: 15px 0;
        font-size: 16px;
        line-height: 30px;
        letter-spacing: 0.05em;
        color: #4140DF;
        text-align: left;
        text-transform: uppercase;
    }

    .title {
        text-align: left;
        font-size: 48px;
				font-weight: 900;
				letter-spacing: 0.03em;
        line-height: 56px;
        color: #131F37;
    }

    @media (max-width: 768px) {
        padding: 50px 0 20px;
        margin: 0 20px;
    }
}

.header-banner {
    width: 100%;
    height: 550px;
    position: relative;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        animation: fade-in 1.2s 0.2s ease-in-out;
        animation-fill-mode: backwards;
    }
    @media (max-width: 768px) {
        height: 300px;
    }
}

.content {
    animation: fade-in 1s;
    position: relative;
    z-index: 2;
    margin: auto;

    @media (max-width: 768px) {
        width: auto;
        margin: 0 20px;
    }

    ._markdown_ {
        display: flex;
        flex-direction: column;
        max-width: 580px;
        width: 100%;
        margin: 0 auto;
    }

    p,
    ul,
    li,
    h2,
    h3 {
        animation: slide-up 0.4s, fade-in 0.6s;
    }

    img,
    hr {
        animation: fade-in 1.2s 0.2s ease-in-out;
        animation-fill-mode: backwards;
    }

    p,
    ul,
    ol,
    li {
        font-size: 16px;
        font-weight: 400;
        line-height: 28px;
        color: #485776;
        letter-spacing: 0.02em;
        padding: 10px 0;
        max-width: 580px;
        width: 100%;
        -webkit-font-smoothing: antialiased;
        word-wrap: break-word;

        a {
            color: #4140DF;
            word-wrap: break-word;
            cursor: pointer;
        }

        a:hover {
            border-bottom: 1px solid #4140DF;
        }
    }

    code {
        font-family: monospace;
        word-wrap: break-word;
        color: $text-main;
        background: rgba(114, 126, 151, 0.1);
        border-radius: 10px;
        padding: 20px;
    }

    ul {
        padding-left: 30px;
        width: unset;
    }

    ol {
        padding-left: 20px;
        width: unset;
    }

    li {
        padding: 10px 0;
        word-wrap: break-word;
    }


    hr {
        border: none;
        height: 1px;
        background: linear-gradient(45deg, $q-teal -60%, $q-purple);
        margin: 60px 0;
    }

    h1 {
        letter-spacing: 1.4px;
        font-size: 3em;
        font-weight: 600;
        -webkit-font-smoothing: antialiased;
        margin: 50px 0 30px;
        word-wrap: break-word;
    }

    h2 {
        letter-spacing: 1.4px;
        font-size: 2em;
        font-weight: 600;
        -webkit-font-smoothing: antialiased;
        margin: 50px 0 30px;
        word-wrap: break-word;
    }

    h3 {
        letter-spacing: 1.4px;
        font-size: 1.6em;
        font-weight: 600;
        margin: 50px 0 30px;
        -webkit-font-smoothing: antialiased;
        word-wrap: break-word;
    }

		table {
				border: 1px solid #485776;
				border-collapse: collapse;

				td {
						border-bottom: 1px solid #485776;
						padding: 15px;

						&:first-of-type {
							width: 30%;
							font-weight: 600;
						}
				}
		}

    .glossary-header {
        font-size: 25px;
        font-weight: 800;
        line-height: 32px;
        letter-spacing: 0.15em;
        text-transform: uppercase;
        color: #50545f;
        position: relative;
        text-align: left;
        word-wrap: break-word;
        margin-top: 60px;

        &:before {
            content: '';
            background: #2ccfd0;
            position: absolute;
            left: 0;
            height: 2px;
            width: 50px;
            top: -20px;
        }
    }

    .wrapper-separator {
        position: relative;
        padding-bottom: 40px;
    }

    .wrapper-separator:after {
        content: '';
        background: #2ccfd0;
        position: absolute;
        left: 0;
        height: 2px;
        width: 50px;
        bottom: 0px;
    }

    .wrapper-separator li:last-child {
        padding-bottom: 0px;
    }

    @media only screen and (min-width: 320px) and (max-width: 812px) {
        h1 {
            font-size: 28px;
            margin-left: -15px;
            margin-top: 20px;
            width: 236px;
        }

        h2 {
            font-size: 16px;
        }

        h3 {
            font-size: 16px;
        }

        hr {
            margin: 20px 0 30px 0;
        }

        p,
        ul,
        ol,
        li {
            line-height: 24px;
        }
    }

    @media only screen and (min-width: 376px) {
        h1 {
            width: 100% !important;
        }
    }
}

.letter-wrapper {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .letter {
        margin-right: 5px;
        cursor: pointer;
        text-decoration: none;
        padding: 5px 13px;
        border-radius: 9px;

        .text {
            letter-spacing: unset;
        }

        &:hover {
            background: #2ccfd0;
            border-radius: 25px;

            .text {
                color: #f5f7f9;
            }
        }
    }
}

.image-wrapper {
    text-align: center;
}
