@keyframes slide-up {
    from {
        transform: translateY(120px);
    }
    to {
        transform: translateX(0)
    }
}

@keyframes slide-up-2 {
    from {
        transform: translateY(40px);
    }
    to {
        transform: translateX(0)
    }
}